import CmsContentButton from "components/cms/cmsContent/cmsContentButton/cmsContentButton";
import TextWithImageSpace from "components/contentelements/textWithImage/textWithImageSpace";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { contentElementSettings } from "services/contentElementSettings/contentElementSettingsService";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import { setPageVisibility } from "store/actions";
import { axiosGetRequest } from "utils/clientUtil";
import { MOBILE_SCALING_FACTOR } from "utils/constants";

/**
 * Since we use nexti18next and useTranslation hook all tranlation keys
 * are getting injected through serverSideTranslations.
 * All client side rendered components like 404.js that dont use
 * serverSideTranslations cant access the language keys because they are not
 * injected through serverSideTranslations.
 *
 * This is how we use translation in client side rendered components/pages
 * https://github.com/i18next/i18next-http-backend/tree/master/example/next
 *
 * Currently there is this issue on all client side rendered components
 * that use client side translations:
 *    Warning: Text content did not match. Server: ...
 *
 * For now there is no need to change this, because this is just an issue
 * with client side pages with translations. The texts will get translated
 * correctly. The server has the language key context, the client
 * side doesnt until the keys are loaded asynchronous on the client side.
 * This is why the warning "text did not match server..." happens.
 *
 *
 * @param {*} props
 * @returns
 */
export default function Custom404(props) {
  const settings = contentElementSettings.textwithimage;
  const translation = useTranslation("public");
  const { t: tPublic } = translation;
  const dispatch = useDispatch();
  const [cmsLanguageFetched, setCmsLanguageFetched] = useState(false);

  useEffect(() => {
    async function fetchLocales() {
      const langResult = await axiosGetRequest(
        `/locales/${process.env.NEXT_PUBLIC_CMS_USER_LANGUAGE}/cms.json`
      );

      if (langResult.success) {
        const data = langResult.response.data;
        window.localStorage.setItem("i18next_res_de-cms", JSON.stringify(data));

        translation.i18n.store.data.de.cms = data;

        // Update state to rerender page (fixes topbar problem)
        dispatch(setPageVisibility(false));
        dispatch(setPageVisibility(true));
      }
      setCmsLanguageFetched(true);
    }
    if (!cmsLanguageFetched && translation.ready) {
      fetchLocales();
    }
  }, [
    translation.ready,
    cmsLanguageFetched,
    dispatch,
    translation.i18n.store.data.de,
  ]);

  return (
    <>
      <div className="page-not-found-container">
        <div className="container-fluid text-with-image-container">
          <Row className="g-0">
            <Col
              xs={12}
              md={6}
              className="image-col my-md-auto order-3 half-spacer-middle-right"
            >
              <div className="cms-content-image">
                <img src={"/404.svg"} alt={"page not found image"} />
              </div>
            </Col>
            <Col
              xs={12}
              md={6}
              className="text-col order-2 half-spacer-middle-left"
            >
              <Row className="edge-to-edge-row">
                <Col xs={12} className="heading-col s-pb-1">
                  <h2 className="heading">{tPublic("pageDoesntExist")}</h2>
                </Col>
              </Row>
              <Row className="edge-to-edge-row mt-5">
                <Col xs={12} className="button-col">
                  <CmsContentButton
                    styleType="primary"
                    text={tPublic("toHomepage")}
                    url={"/"}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <TextWithImageSpace settings={settings} />
      <style jsx>
        {`
          /* Spacer Middle */
          /* Mobile-Handling: */
          /* Note: For the moment hardcoded reduced by factor 0.5 (below tablet) */
          .page-not-found-container :global(.half-spacer-middle-right) {
            padding-top: ${(settings.spacerMiddlePX / 2) *
              MOBILE_SCALING_FACTOR +
            "px"};
          }

          .page-not-found-container :global(.half-spacer-middle-left) {
            padding-bottom: ${(settings.spacerMiddlePX / 2) *
              MOBILE_SCALING_FACTOR +
            "px"};
          }

          /* Important: Only on non-Mobile-View */
          @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
            .page-not-found-container :global(.half-spacer-middle-right) {
              padding-left: ${settings.spacerMiddlePX / 2 + "px"} !important;

              /* Override mobile padding-y */
              padding-top: 0;
              padding-bottom: 0;
            }

            .page-not-found-container :global(.half-spacer-middle-left) {
              padding-right: ${settings.spacerMiddlePX / 2 + "px"} !important;

              /* Override mobile padding-y */
              padding-top: 0;
              padding-bottom: 0;
            }
          }
        `}
      </style>
    </>
  );
}
